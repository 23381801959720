import { injectable } from 'inversify-props';
import { ClientTypeEnum } from '../enums/client-type.enum';
import ClientModel from '@/models/crm/client.model';

@injectable()
export default class SessionService {
  private token!: string;

  private url!: string;

  private url360!: string;

  private socketUrl!: string;

  private builtIn!: boolean;

  private appBar!: boolean;

  private clientTypeMode!: ClientTypeEnum;

  private client!: ClientModel;

  private order!: string;

  private admOrRoot = true;

  private websocketCC!: string;

  private extensionCC!: string;

  private idCC!: string;

  private showStatusHeaderCC!: boolean;

  private partnerApiCC!: string;

  private partnerCodeCC!: string;

  private showNotificationCC!: boolean;

  get isAdmOrRoot(): boolean {
    return this.admOrRoot;
  }

  set isAdmOrRoot(admOrRoot: boolean) {
    this.admOrRoot = admOrRoot;
  }

  get apiToken(): string {
    return this.token;
  }

  set apiToken(token: string) {
    this.token = token;
  }

  get orderCode(): string {
    return this.order;
  }

  set orderCode(orderCode: string) {
    this.order = orderCode;
  }

  get apiUrl(): string {
    return this.url;
  }

  set apiUrl(url: string) {
    this.url = url;
  }

  get apiUrl360(): string {
    return this.url360;
  }

  set apiUrl360(url360: string) {
    this.url360 = url360;
  }

  get socketIOUrl(): string {
    return this.socketUrl;
  }

  set socketIOUrl(socketUrl: string) {
    this.socketUrl = socketUrl;
  }

  get builtInMode(): boolean {
    return this.builtIn;
  }

  set builtInMode(builtInMode: boolean) {
    this.builtIn = builtInMode;
  }

  get hideAppBar(): boolean {
    return this.appBar;
  }

  set hideAppBar(hideAppBar: boolean) {
    this.appBar = hideAppBar;
  }

  get clientType(): ClientTypeEnum {
    return this.clientTypeMode || ClientTypeEnum.Client;
  }

  set clientType(clientType: ClientTypeEnum) {
    this.clientTypeMode = clientType;
  }

  get activeClient(): ClientModel {
    return this.client;
  }

  set activeClient(client: ClientModel) {
    this.client = client;
  }

  get websocketCallCenter(): string {
    return this.websocketCC;
  }

  set websocketCallCenter(webscoket: string) {
    this.websocketCC = webscoket;
  }

  get extensionCallCenter(): string {
    return this.extensionCC;
  }

  set extensionCallCenter(ramal: string) {
    this.extensionCC = ramal;
  }

  get idCallCenter(): string {
    return this.idCC;
  }

  set idCallCenter(id: string) {
    this.idCC = id;
  }

  get showStatusCallCenterHeader(): boolean {
    return this.showStatusHeaderCC;
  }

  set showStatusCallCenterHeader(status: boolean) {
    this.showStatusHeaderCC = status;
  }

  get partnerApiCallCenter(): string {
    return this.partnerApiCC;
  }

  set partnerApiCallCenter(api: string) {
    this.partnerApiCC = api;
  }

  get partnerCodeCallCenter(): string {
    return this.partnerCodeCC;
  }

  set partnerCodeCallCenter(codigo: string) {
    this.partnerCodeCC = codigo;
  }

  get showNotificationCallCenter(): boolean {
    return this.showNotificationCC;
  }

  set showNotificationCallCenter(show: boolean) {
    this.showNotificationCC = show;
  }
}
