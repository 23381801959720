import { NavigationGuardNext, Route, Location } from 'vue-router';
import { inject } from 'inversify-props';
import { SessionDataEnum } from '@/enums/session-data.enum';
import SessionService from '@/services/session.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientTypeEnum } from '@/enums/client-type.enum';

export default class AuthGuard {
  protected readonly forbiddenRoute = '/forbidden';

  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  resolve(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.name !== 'forbidden') {
      const token = to.query[SessionDataEnum.ApiToken] as string;
      const apiUrl = to.query[SessionDataEnum.ApiUrl] as string;
      const apiUrl360 = to.query[SessionDataEnum.ApiUrl360] as string;
      const socketIOUrl = to.query[SessionDataEnum.SocketIOUrl] as string;
      const builtInMode = to.query[SessionDataEnum.BuiltInMode] as string;
      const hideAppBar = to.query[SessionDataEnum.HideAppBar] as string;
      const clientType = to.query[SessionDataEnum.ClientType] as ClientTypeEnum;
      const orderCode = to.query[SessionDataEnum.OrderCode] as string;
      const admOrRoot = to.query[SessionDataEnum.AdmOrRoot] as string;
      const websocketCallCenter = to.query[SessionDataEnum.WebsocketCallCenter] as string;
      const extensionCallCenter = to.query[SessionDataEnum.ExtensionCallCenter] as string;
      const idCallCenter = to.query[SessionDataEnum.IdCallCenter] as string;
      const showStatusCallCenterHeader = to.query[SessionDataEnum.ShowStatusCallCenterHeader] as string;
      const partnerApiCallCenter = to.query[SessionDataEnum.PartnerApiCallCenter] as string;
      const partnerCodeCallCenter = to.query[SessionDataEnum.PartnerCodeCallCenter] as string;
      const showNotificationCallCenter = to.query[SessionDataEnum.ShowNotificationCallCenter] as string;

      if (admOrRoot) {
        this.sessionService.isAdmOrRoot = admOrRoot === '1';
      }

      if (token) {
        this.sessionService.apiToken = token;
      }

      if (apiUrl) {
        this.sessionService.apiUrl = apiUrl;
      }

      if (apiUrl360) {
        this.sessionService.apiUrl360 = apiUrl360;
      }

      if (socketIOUrl) {
        this.sessionService.socketIOUrl = socketIOUrl;
      }

      if (builtInMode) {
        this.sessionService.builtInMode = builtInMode.toLowerCase() === 'true';
      }

      if (hideAppBar) {
        this.sessionService.hideAppBar = hideAppBar.toLowerCase() === 'true';
      }

      if (clientType) {
        this.sessionService.clientType = clientType;
      }

      if (orderCode) {
        this.sessionService.orderCode = orderCode;
      }

      if (websocketCallCenter) {
        this.sessionService.websocketCallCenter = websocketCallCenter;
      }

      if (extensionCallCenter) {
        this.sessionService.extensionCallCenter = extensionCallCenter;
      }

      if (idCallCenter) {
        this.sessionService.idCallCenter = idCallCenter;
      }

      if (showStatusCallCenterHeader) {
        this.sessionService.showStatusCallCenterHeader = showStatusCallCenterHeader.toLowerCase() === 'true';
      }

      if (partnerApiCallCenter) {
        this.sessionService.partnerApiCallCenter = partnerApiCallCenter;
      }

      if (partnerCodeCallCenter) {
        this.sessionService.partnerCodeCallCenter = partnerCodeCallCenter;
      }

      if (showNotificationCallCenter) {
        this.sessionService.showNotificationCallCenter = showNotificationCallCenter.toLowerCase() === 'true';
      }

      if (!this.sessionService.apiToken) {
        return next(this.forbiddenRoute);
      }
    }

    // Ensure route has token and apiUrl
    const hasSessionQueryParams = (route: Route) => {
      const queryKeys = Object.keys(route.query);
      const sessionKeys = queryKeys.filter(
        (item) => item === SessionDataEnum.ApiToken || item === SessionDataEnum.ApiUrl,
      );
      return sessionKeys.length;
    };

    if (!hasSessionQueryParams(to) && hasSessionQueryParams(from)) {
      const {
        token,
        apiUrl,
        socketIOUrl,
        builtInMode,
        hideAppBar,
        clientType,
        websocketCallCenter,
        extensionCallCenter,
        idCallCenter,
        showStatusCallCenterHeader,
        partnerApiCallCenter,
        partnerCodeCallCenter,
        showNotificationCallCenter,
      } = from.query;
      return next({
        ...(to as Location),
        query: {
          token,
          apiUrl,
          socketIOUrl,
          builtInMode,
          hideAppBar,
          clientType,
          websocketCallCenter,
          extensionCallCenter,
          idCallCenter,
          showStatusCallCenterHeader,
          partnerApiCallCenter,
          partnerCodeCallCenter,
          showNotificationCallCenter,
        },
      });
    }

    return next();
  }
}
